header {
  margin-top: 30px;
  margin-bottom: 50px;
}

@media only screen and (max-width: 959px) {
  .nav {
    text-align: center;
  }
}

@media only screen and (max-width: 599px) {
  .nav {
    text-align: end;
  }
}


footer {
  position: relative;
  bottom: 0;
  text-align: center;
}

.logo {
  max-width: 100%;
  min-width: 160px;
  max-height: 120px;
}

.img {
  position: fixed;
  top: 0;
  right: 0;
  z-index: -1;
  max-width: 40%;
}

