.pageMain {
  display: grid;
  grid-template-rows: 250px min-content min-content;
  grid-template-columns: 1fr;
  width: 80%;
  margin: 0 auto;
}

@media only screen and (max-width: 1500px) {
  .pageMain {
    display: grid;
    grid-template-rows: 250px min-content min-content;
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 1366px) {
  .service_card {
    height: 100%;
  }
}

@media only screen and (max-width: 1080px) {
  .pageMain {
    display: grid;
    grid-template-rows: 250px min-content min-content;
    grid-template-columns: 1fr;
    width: 100%;
  }

  .body {
    margin: 0 20px;
  }

  #logo {
    justify-self: center;
  }

  .contactBody {
    margin: 0 20px;
  }
}

@media only screen and (max-width: 740px) {
  #praxis {
    grid-template-columns: 1fr !important;
    grid-template-rows: repeat(2, 1fr);
    row-gap: 40px;
  }
}

@media only screen and (max-width: 600px) {
  .pageMain {
    display: grid;
    grid-template-rows: 250px min-content min-content;
    grid-template-columns: 1fr;
  }

  .body {
    margin: 0 20px;
  }

  .welcomeText {
    display: grid;
    grid-template-columns: 1fr !important;
    margin: 20px 0;
  }
}

.main {
  display: grid;
  grid-template-rows: calc(100vh - 400px) 1fr;
  grid-template-columns: 1fr;
}

.header {
  display: grid;
  grid-template-rows: 1fr 1px 50px;
}

#headerName {
  display: none;
}

.menu {
  display: grid;
  grid-template-columns: repeat(5, min-content);
  align-items: center;
  text-align: center;
  justify-content: space-between;
  white-space: nowrap;
  margin: 0 30px;
}

.divider {
  border-top: 1px solid rgba(90, 158, 53, 0.3);
}

#logo {
  align-self: center;
}

.gallery {
  max-height: 700px;
}

.body {
  display: grid;
  grid-template-rows: repeat(4, min-content);
}

.welcome {
  margin: 0 10px;
  font-size: 16px !important;
}

.topElement {
  margin: 50px 0 10px;
}

.welcomeText {
  display: grid;
  grid-template-columns: 2fr 1fr;
  margin: 20px 0;
}

#main_service {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  column-gap: 20px;
}

#extras_service {
  display: grid;
  grid-template-columns: 0.5fr repeat(2, 1fr) 0.5fr;
  justify-content: space-between;
  column-gap: 20px;
}

#praxis {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
  column-gap: 40px;
}

.praxis_points {
  display: grid;
  grid-template-rows: repeat(5, min-content);
  align-content: space-around;
}

.praxis_point {
  display: grid;
  grid-template-columns: min-content 1fr;
  column-gap: 40px;
  align-items: center;
  margin: 10px 0;
}

.praxis_icon {
  height: 50px;
}

.praxis_icon img {
  height: 100%;
}

.praxis_desc {
  font-size: 20px;
  letter-spacing: 1.5px;
  font-weight: 300;
}

.carouselBody {
  overflow: hidden;
}

.service_category {
  margin: 50px 0 10px;
  text-align: center;
}

.service_card {
  display: grid;
  place-items: center;
  background-color: #c5e3b2;
  padding: 40px 0;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
  grid-template-rows: repeat(3, min-content) 1px min-content;
}

.service_card_extra_line {
  grid-template-rows: repeat(4, min-content) 1px min-content;
}

.service_img {
  background-color: #FFFFFF;
  padding: 6px;
  border: 3px solid #4b9426;
  border-radius: 50%;
  height: 250px;
  width: 250px !important;
  object-fit: cover;
}

.service_divider {
  border-top: 1px solid rgba(90, 158, 53, 0.3);
  width: 80%;
}

.service_name {
  font-weight: bold;
  font-size: 20px;
  margin: 20px 0 0;
  text-align: center;
}

.service_price {
  font-weight: bold;
  font-size: 20px;
  margin: 10px 0 20px;
}

.service_addon {
  font-size: 12px;
  margin: -10px 30px 10px;
  text-align: center;
}

.service_desc {
  font-size: 16px;
  margin: 10px 0 0;
  text-align: center;
  line-height: 30px;
}

.footer {
  display: grid;
  margin-top: 20px;
  grid-template-rows: repeat(2, min-content);
  place-items: center;
  text-align: center;
}
